import React from "react";
import { Link } from "gatsby";
import "../pages/blog/index.scss";
import blogs from '../components/BlogsData';

const HomePageBlogs = () => {
  // Sort blogs by date and take the last 3
  const recentBlogs = blogs.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 3);
  return (
    <>
      <div className="homepageblogs">
        <div className="container">
          <div className="blogs_header">
            <h3>Latest Blogs</h3>
          </div>
          <div className="columns">
            {recentBlogs.map((blog, index) => (
              <div key={"col_"+blog.id} className="column">
                <div className="b_header">
                  <Link to={blog.path}>
                    <img src={blog.image} alt={blog.title} />
                  </Link>
                </div>
                <div className="b_content">
                  <div className="b_head">
                    <h3 className="b_title">
                      <Link to={blog.path}> {blog.title}</Link>
                    </h3>
                    <p className="b_date">Posted on - {blog.date}</p>
                  </div>
                  <div className="b_text">
                      {blog.description}
                  </div>
                  <div className="b_bottom_btn"> <Link to={blog.path}>Read More</Link></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
};
export default HomePageBlogs;
import React from "react";
import Nav from "../components/nav";
import promo from "../video/bg-promo-video.mp4";
import homeStyles from "../components/home.module.scss";
import HeroVideo from "../components/video";
import rfLogo from "../images/rf-logo-light.svg";
import homeBanner from "../images/homeoffer.jpg";
import Layout from "../components/layout-default";
import HomePageBlogs from "../components/HomePageBlogs";
import HomePagePricing from "../components/HomePagePricing";
import GoogleReviews from "../components/GoogleReviews";
import TestimonialsHomePage from "../components/TestimonialsHomePage";
import "../styles/layout.scss";

import bachata from "../images/bachata-home.jpg"
import salsa from "../images/salsa-home.jpg"

import { Link } from "gatsby";



const FooterColumn = (props) => (
  <div className={`${homeStyles.column} column`}>
    <h2>
      <a href={props.href}>
        <span className={homeStyles.title}>{props.title}</span>
        <span className={homeStyles.subtitle}>{props.subtitle}</span>
      </a>
    </h2>
  </div>
);

const HeroFull = () => {
//  
  const HeroCard = [
    {
      id: 1,
      title: "INTRO OFFER",
      desc: `Get a Taste of our Membership!|Experience our Dance community by trying out a week of Unlimited Salsa, Bachata, Cumbia & Dance Fit classes.`,
      button: "$29 unlimited classes for one week",
      link: "/classes"
    },
    {
      id: 2,
      title: "RF MEMBERSHIP",
      subTitle : "Best of Value",
      desc: `Discounted Private Lessons| Monthly Socials| Unlimited RF Group Classes| Over 55 Classes Offered!| Salsa, Bachata, Cumbia Dance Fit & More!`,
      button: "Sign up",
      link: "/classes"
    },
    {
      id: 3,
      title: "FIRST CLASS FREE",
      desc: "Sign Up now for your First Free Class!|Come Meet the Teachers and some of our Students|Check out our Venue|",
      button: "Get 1 free salsa, bachata Class",
      link: "/prospect"
    }
  ];


  return (<div className={homeStyles.hero}>
    <div className={homeStyles.heroHead}>
      <Nav isHome />
    </div>

    <div className={homeStyles.heroBody}>

      <div className="container">
        <div className="text-center">
          <div className={homeStyles.heroContent}>
            <img src={rfLogo} alt="RF Dance" />
          </div>

          <div className={homeStyles.heroContent}>
            <div className="video-section">
              <HeroVideo
                promo={promo}
                primaryLink="/classes"
                primaryLinkText=""
              />
              <ul className="notMobileView forMobiles">

                {HeroCard.map((item, index) => (
                  <li key={index}>
                    <div>
                      <Link className="notMobileView forMobiles" to={item.link}>
                        <h2>{item.title}</h2>
                      </Link>
                    </div>
                    <div>
                      {typeof item.desc === 'string' && item.desc.split('|').map((descPart, partIndex) => (
                        <p key={partIndex} style={{ color: "#000" }}>{descPart.trim()}</p>
                      ))}
                    </div>
                    <div className={`is-mobile-only is-hidden-tablet ${homeStyles.calloutLinkBox}`}>
                      <br />
                      <div className="unlimited_links">
                        <Link className="norap-css" to={item.link}>
                          <p style={{ color: "#f7f7f7" }}>{item.button}</p>
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="heroFoot-parentDiv">
      <div className={homeStyles.heroFoot}>
        <div className="main-head">

          <div className="video-section">
            <ul>
              {HeroCard.map((item, index) => (
                <li key={index}>
                  <div>
                    <Link className="notMobileView forDesktop" to={item.link}>
                      <h2>{item.title}</h2>
                      <h3>{item.subTitle}</h3>
                    </Link>

                    <p style={{ color: "#000" }}>{typeof item.desc === 'string' && item.desc.split('|').map((descPart, partIndex) => (
                      <p key={partIndex} style={{ color: "#000" }}>{descPart.trim()}</p>
                    ))}</p>
                    
                    <br />
                    <div className="unlimited_links">
                      <Link className="norap-css" to={item.link}>
                        <p style={{ color: "#f7f7f7" }}>{item.button}</p>
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>


          {/* <div className="homer_banner">
          <div className="container">
            <img src={homeBanner} className="homeBannerImg" alt="Super Sale" />
          </div>
         </div> */}
          <div className="content-block">
            <div className="container">
              {/* Dance Styles At RF Dance */}

              <h2 style={{ color: "#000" }} >Dance Styles At RF Dance</h2>
              <p style={{ color: "#000" }}>
                Take your dance skills to the next level with RF Dance. We have dedicated salsa and bachata classes with flexible schedules. Below is more on the dance styles we teach at RF Dance.
              </p>
              <br />
              <div className="dance-image" style={{ display: 'inline-flex' }}>
                <div className='image_container'>
                  <Link to='https://rfdance.com/classes/bachata-dance-classes-in-orange-county-ca'>
                    <img src={bachata} alt="Bachata Dance " />
                  </Link>
                </div>
                <div className='image_container'>
                  <Link to='https://rfdance.com/classes/salsa-dance-classes-in-orange-county-ca'>
                    <img src={salsa} alt="Salsa Dance" />
                  </Link>
                </div>
              </div>

              <br />
              <br />
              <div className="county-text">
                <h1 style={{ color: "#000" }}>Dance Studios in Orange County</h1>
                <p style={{ color: "#000" }}>
                  Welcome to RF Dance, one of the premier dance studios in Orange
                  County! Dancing is not just a form of exercise but also a means of
                  expression. RF Dance Academy understands this, and thus we provide
                  a platform for dance enthusiasts to learn and excel in their
                  dancing skills. We offer salsa and <Link to="/classes/bachata-dance-classes-in-orange-county-ca">bachata dance </Link> styles for everyone. So, whether you're a
                  beginner or an advanced dancer, we have dance lessons in Orange
                  County for every age group and preference. Step up your dance game
                  with the best adult dance classes in Orange County. Our
                  state-of-the-art facilities and experienced instructors create a
                  warm and welcoming environment where our students can explore and
                  develop their passion for dance. We provide personalized attention
                  and guidance to help you brush up your dancing skills and achieve
                  your dance goals.
                </p>
              </div>

              <div className="video-section">
                <ul>
                  {" "}
                  <li>
                    <iframe
                      width="420"
                      height="345"
                      src="https://www.youtube.com/embed/AOct8eMyUtw"

                    ></iframe>
                  </li>
                  <li>
                    <iframe
                      width="420"
                      height="345"
                      src="https://www.youtube.com/embed/YGxXkHIFseI"


                    ></iframe>
                  </li>
                  <li>
                    <iframe
                      width="420"
                      height="345"
                      src="https://www.youtube.com/embed/yrhmvOOcriA"
                    ></iframe>
                  </li>
                </ul>
              </div>
              <div className="video-left video-tab-section">
                <div className="county-text">
                  <h2 style={{ color: "#000" }}>Dance Classes in Orange County</h2>
                  <p style={{ color: "#000" }}>
                    RF dance classes in Orange County offer a spacious and
                    professional environment for dancers to hone their skills.
                    Equipped with top-of-the-line sound systems and comfortable
                    dance floors, our studios are perfect for rehearsals, auditions,
                    and performances. Our well-ventilated and well-lit spaces also
                    provide a safe and healthy environment for our dancers. At RF
                    Dance, we also offer <Link to="/classes/salsa-dance-classes-in-orange-county-ca">salsa classes in orange county</Link> that are
                    perfect for individuals who want to learn this exciting dance
                    style, spice up their dance moves, and explore Latin rhythms.
                  </p>

                  <h3 style={{ color: "#000", fontSize: '30px' }}>Why Choose Us In Orange County For Dance Lessons</h3>
                  <p style={{ color: "#000" }}>
                    At the RF Dance academy, we're committed to providing the best
                    dance education in Orange County. Whether you're looking to
                    improve your dance skills or just want to have fun and stay fit,
                    we have the perfect dance classes for you. So, what are you
                    waiting for? Contact us today to learn more about our dance
                    classes in Orange County, CA, and schedule your first class.
                  </p>
                </div>
                <div className="video-section" style={{ textAlign: "center" }}>
                  <ul>
                    {" "}
                    <li>
                      {" "}
                      <iframe
                        width="420"
                        height="450"
                        src="https://www.youtube.com/embed/V9Gs2-kMGFI"
                      ></iframe>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="county-text">
          <h3 style={{ color: "#000" }}>
            Why Choose Us In Orange County For Dance Lessons
          </h3>
          <p style={{ color: "#000" }}>
            At the RF Dance academy, we're committed to providing the best dance
            education in Orange County. Whether you're looking to improve your
            dance skills or just want to have fun and stay fit, we have the
            perfect dance classes for you. So, what are you waiting for? Contact
            us today to learn more about our dance classes in Orange County, CA,
            and schedule your first class.
          </p>
        </div> */}
              {/* <div className="video-section" style={{ textAlign : "center"}}>
        <ul><li> <iframe
            width="420"
            height="345"
            src="https://www.youtube.com/embed/FjFLc6boPJI"
          ></iframe></li></ul>
        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );

}



export default () => (
  <Layout
    title="Dance Studios in Orange County | RF Dance"
    description="Discover the best dance studios in Orange County at rfdance.com. From beginners to experts, our classes cater to all levels. Join us and dance like a pro!"
    pathname=""
    className="homepage"
  >
    <HeroFull />
    {/* <HomePagePricing /> */}
    <TestimonialsHomePage />
    <HomePageBlogs />
    <GoogleReviews />

  </Layout>
);

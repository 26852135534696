import React from "react";
import googleImg from "../images/google.svg";
import { Link } from "gatsby";
import "../styles/layout.scss"


const GoogleReviews = () => (
    <div className="google_review">
        <div className="container">
            <div className="review_section">
                <div className="ti-header source-Google">
                    <div className="google-trace">
                        <div className="ti-large-logo">
                            <div className="ti-v-center">
                                <img className="ti-logo-fb" src={googleImg} alt="Google" width="150" height="25" />
                            </div>
                        </div>
                        <span className="ti-stars star-lg"> <span className="ti-star f"></span><span className="ti-star f"></span><span className="ti-star f"></span><span className="ti-star f"></span><span className="ti-star f"></span> </span>
                        <div className="ti-rating-text"> <span className="nowrap">Based on <strong>65 reviews</strong></span></div>
                    </div>
                    <div className="review_btn">
                        <Link target="_blank" to="https://www.google.com/search?q=RF+DANCE+CENTER&oq=RF+DANCE+CENTER&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg80gEHMTQzajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x80dcd8aee9e55555:0xe582828dbc33fad2,1,,,,">Reviews</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default GoogleReviews;